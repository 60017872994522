@import "bootstrap";

@mixin shadow-on-hover {
    transition: all .1s ease-in-out;

    &:hover {
        transform: scale(1.03);
        box-shadow: 0 8px 16px rgba(0,0,0,0.2);
        z-index: 1;
    }
}

:root {
    --primary-color: #e01616;
    --primary-color-dark: #4f1111;
    --primary-transparent-background-color: #e016161a;
    --secondary-color: #ba4242;
    --secondary-light-color: #ffefd0;
    --black-color: black;
    --white-color: white;
    --text-color: #2c3e50;
}

body {
    padding-top: 50px;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
    white-space: normal;
}



h1, h2, h3, h4, h5, h6, body {
    font-family: "Noto Sans", Helvetica, Arial, sans-serif;
}

h3 {
    font-size: 2.2em;
    font-weight: bold;
}




.navbar-default .navbar-brand {
    color: var(--black-color);
    font-size: 110%;
}

.navbar-default {
    background: #fff;
    border-bottom: none;
    box-shadow: 0 0 1.5em rgba(0,0,0,.1);
    font-size: 1.3em;
}

    .navbar-default .navbar-nav a.nav-link {
        transition: background .5s, border-color .5s;
        font-weight: 400;
        border-bottom: solid .2em #fff;
        padding: 15px;
    }


    .navbar-default .navbar-nav a.active,
    .navbar-default .navbar-nav a.active:hover,
    .navbar-default .navbar-nav a.active:focus {
        background: #f6f6f6;
        color: var(--primary-color);
        border-bottom-color: var(--primary-color);
    }

@media only screen and (max-width : 992px) {
    .navbar-default {
        font-size: 1.1em;
    }
}

@media only screen and (max-width : 768px) {
    .navbar-default .navbar-nav {
        border-top: solid #ddd 1px;
    }

    .navbar-default .navbar-brand {
        height: auto;
        padding-block: 0.5rem;
        padding-inline: 1rem;
        font-size: 130%;
    }

    .navbar-default .navbar-toggler {
        margin-inline-end: 1rem;
    }

    .navbar-default .container {
        padding-inline: 0;
    }

    .navbar-default .navbar-nav a,
    .navbar-default .navbar-nav a.active:hover,
    .navbar-default .navbar-nav a.active:focus {
        border-bottom: none !important;
    }

    .navbar-default .navbar-brand .divider {
        display: none;
    }

    .subtitle {
        display: block;
        font-size: 60%;
        margin-top: .2em;
        font-weight: bold;
        color: var(--primary-color)
    }
}


/*  FF2900 */

h2 {
    font-weight: 100;
    font-size: 4em;
    text-align: center;
    margin: .4em 0;
}

a {
    color: var(--primary-color);
    border-bottom: solid 2px transparent;
    transition: all .1s ease-in-out;
    font-weight: bolder;
}

    a:hover {
        color: var(--primary-color-dark);
        text-decoration: none;
        border-color: var(--primary-color-dark);
    }

    #intro a,
    a.navbar-brand {
        border: none;
        font-weight: normal;
    }

    a.btn-link {
        color: var(--primary-color);
    }
    a.btn-link:hover {
    color: var(--primary-color-dark);
    }
    
    .image-decorated {
        box-shadow: 0 3px .8em rgba(0,0,0,.9);
        --webkit-box-shadow: 0 0 1.5em rgba(0,0,0,.8);
        border-radius: 7px;
    }

section {
    padding: 3em env(safe-area-inset-right) 3em env(safe-area-inset-left);
}

#intro {
    background: var(--white-color);
    text-align: center;
    min-height: calc(60vh - 50px);
}

.profilePhoto {
    background: url('../img/jindrich-pergler.jpg') bottom center no-repeat;
    background-size: contain;
    border-bottom: solid 2px var(--black-color);
    height: calc(100px + 25vw);
    max-height: 400px;
    aspect-ratio: 5/3;
    margin-inline: auto;
}


#intro h1 {
    font-size: 4em;
    font-weight: 300;
    margin: .5em 0;
}

#intro .subtitle {
    font-weight: bold;
    color: var(--primary-color);
    font-size: 3em;
}

.showMore {
    display: inline-block;
    width: calc(1em + 5vh);
    height: calc(1em + 5vh);
    margin-top: calc(-1em + 5vh);
    background: url('../img/arrow-down.svg') top center no-repeat;
    background-size: cover;
    line-height: 1000em;
    overflow: hidden;
    transition: transform .1s ease-in-out;
}

    .showMore:hover {
        transform: scale(1.1);
    }


#what-i-do {
    background: var(--secondary-color);
    color: var(--white-color);
    text-align: center;
}

    @media (min-width: 992px) {
        #what-i-do .activity-box-list {
            display: flex;
            align-items: stretch;
            align-content: stretch;
            gap: 30px;
        }

        #what-i-do .activity-box {
            width: 32%;
        }
    }


    #what-i-do h3 {
        font-size: 2.2em;
        font-weight: bold;
        min-height: 2.6em;
        margin: .5em 0;
        color: var(--white-color)
    }

    #what-i-do p {
        font-size: 1.3em;
        font-weight: 100;
        color: rgba(255, 255, 255, 0.8);
    }

    #what-i-do .activity-box {
        transition: all .1s ease-in-out;
        padding: 1rem 2rem;
        margin: 2rem 0;
        border-radius: 10px;
    }

        #what-i-do .activity-box:hover,
        #what-i-do .activity-box.highlighted {
            background-color: var(--white-color);
            transform: scale(1.02, 1.02);
        }

        #what-i-do .activity-box:hover h3,
        #what-i-do .activity-box.highlighted h3 {
            color: var(--primary-color);
        }

        #what-i-do .activity-box:hover p,
        #what-i-do .activity-box.highlighted p {
            color: var(--primary-color-dark);
        }


#projects {
    background: var(--secondary-light-color);
    background-size: cover;
    height: auto;
}

    #projects .container-box {
        background: var(--white-color);
        padding: 1em;
        margin: 5em 0;
    }

.projectBox {
    @include shadow-on-hover;

    background: rgba(255, 255, 255, .9);
    border-radius: .3em;
    color: #000;
    padding: 2.5em 2em;
    margin: .8em 0;
    position: relative;
    font-size: 1.1em;
    transition: all .1s ease-in-out;
    text-align: center;
}



    .projectBox:hover {
        transform: scale(1.03);
        background: var(--white-color);
        
    }

    .projectBox h3 {
        margin: 0;
        text-align: center;
        color: var(--primary-color);
    }

    .projectBox p {
        font-size: 1.4em;
        margin: 1.5em 0;
    }

    .projectBox .tags {
        margin: .1em 0;
        font-size: 1.3em;
    }

        .projectBox .tags span,
        .former-project-experience .tags span {
            padding: .15em .30em;
            margin: .1em 0;
            color: var(--primary-color);
            display: inline-block;
            border-radius: .3em;
            background: var(--primary-transparent-background-color);
            transition: all .1s ease-in-out;
        }

        .show-more-projects-link a,
        .hide-more-projects-link a {
            font-size: 1.2em;
            padding-top: 2rem;
        }

.former-project-experience {
    text-align: center;
}

@media (max-width: 991px) {
    .projectBox h3 {
        font-size: 2.2em;
    }

    .projectBox p {
        font-size: 1.3em;
    }

    .projectBox:hover {
        transform: scale(1.01);
    }
}


#technologies {
    background: var(--secondary-color);
    background-size: cover;
    color: var(--white-color);
}

    #technologies h3 {
        color: var(--white-color);
        margin: 40px 0 0 0;
    }

.technologyBox {
    margin-top: 1vh;
    text-align: center;
    padding-bottom: 1em;
    font-size: 1em;
}

    .technologyBox .technology {
        display: inline-block;
        padding: .20em .40em;
        margin: .40em .35em;
        border-radius: .3em;
        background: var(--white-color);
        color: var(--primary-color-dark);
        font-size: 1.1em;
        font-weight: bold;
        box-shadow: 0 0 .2em rgba(0, 0, 0, .30);
        transition: transform .1s ease-in-out;
    }

        .technologyBox .technology:hover {
            transform: scale(1.05);
            background-color: var(--primary-color-dark);
            color: var(--white-color);
        }

.technology.level5 {
    font-size: 2.20em;
}

.technology.level4 {
    font-size: 1.70em;
}

.technology.level3 {
    font-size: 1.40em;
}

.technology.level2 {
    font-size: 1.15em;
}

.technology.level1 {
    font-size: 1.10em;
}

.technology.deprecated {
    font-size: .9em;
}



@media (max-width: 991px) {
    .technologyBox {
        margin-top: 4vh;
    }
}

@media (max-width: 767px) {
    .technologyBox {
        margin-top: 0;
    }
}



#contact {
    background: var(--white-color);
    text-align: center;
    margin-bottom: env(safe-area-inset-bottom);
}

.bio-text {
    margin: 2rem 0 3rem;
    padding-bottom: 4rem;
}

    #contact .profilePhoto {
        height: 8em;
    }

    #contact .contact-items {
        font-size: 1.8em;
        display: inline-block;
    }

    #contact h3 {
        font-size: 1.8em;
        font-weight: 100;
        margin: .5em 0 .3em;
        padding: 0;
    }

    #contact .links {
        margin: 15px 0;
    }

        #contact .links a {
            background: url("../img/linkedin.svg") top left no-repeat;
            background-size: contain;
            line-height: 1000rem;
            overflow: hidden;
            height: 50px;
            width: 50px;
            display: inline-block;
            border: none;
            transition: transform .1s ease-in-out;
        }

        #contact .links a:hover {
            transform: scale(1.1);
        }

        #contact .links .xing {
            background-image: url("../img/xing.svg");
        }
        #contact .links .cv {
            background-image: url("../img/pdf-cv.svg");
        }

    #contact .subtitle {
        font-weight: bolder;
        font-size: 1.3em;
        color: var(--primary-color);
    }

    #contact .email,
    #contact .identification {
        margin-top: 15px;
    }

    #contact p {
        font-size: 1.4em;
    }

        #contact p a {
            color: var(--primary-color)
        }

.contact-form {
    padding: 2em;
    border-radius: 10px;
    font-size: calc(.7em + 1.5vh);
    text-align: left
}

    .contact-form input,
    .contact-form textarea,
    .contact-form button {
        font-size: 100%;
        height: auto;
        border-width: .1em;
        box-shadow: none;
        border-radius: 2px;
        border-color: var(--white-color);
        transition: all .1s ease-in-out;
    }

        .contact-form input:focus,
        .contact-form textarea:focus {
            box-shadow: none;
            border-color: #333;
        }

    .contact-form label {
        color: #333;
        font-weight: 400;
    }

    .contact-form .error {
        color: #9d0000;
    }

.contact-form-success-message,
.contact-form-failure-message {
    font-size: 2em;
    color: #fff;
    padding: 10vh 7vw;
    border-radius: .5em;
    text-align: center;
    margin-top: 10vh;
}

.contact-form-success-message {
    background: #619d00;
}

.contact-form-failure-message {
    background: #9d0000;
}

    .contact-form-failure-message a {
        color: #F1D6A0;
    }

        .contact-form-failure-message a:hover {
            color: white;
            border-color: white;
        }




footer {
    background: #4F1111;
    color: #f4eaea;
    text-align: center;
    padding: 1rem 0;
}


.plastic-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    -webkit-backdrop-filter: blur(3px) opacity(.15);
    backdrop-filter: blur(3px) opacity(.15);
    z-index: 9999;
}

/* References Section Styles */
#references {
    background-color: white;
    position: relative;
    padding: 6rem 0;
}

.references-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.reference-box {
    @include shadow-on-hover;
    
    background: var(--secondary-light-color);
    padding: 3rem;
    border-radius: 8px;
    border: 1px solid #ba424279;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.reference-text {
    flex-grow: 1;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    line-height: 1.6;
    color: black;
}

.reference-author {
    margin-top: auto;
    
    strong {
        display: block;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
    }
}

.reference-position {
    color: black;
    opacity: 0.8;
    font-size: 1rem;
}

@media (max-width: 768px) {
    #references {
        padding: 4rem 0;
    }

    .references-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .reference-box {
        padding: 2rem;
    }

    .reference-text {
        font-size: 1.1rem;
        margin-bottom: 1.5rem;
    }

    .reference-author strong {
        font-size: 1.1rem;
    }
}